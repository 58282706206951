<template>
  <div class="centro">
    <v-card outlined id="cartao" class="mx-auto elevation-10 mb-2">
      <v-card-title class="mb-10">
        <svg-icon height="50px" width="50px" type="mdi" :path="icone"></svg-icon>
        <h3 v-if="this.$route.fullPath == '/imoveis/new'">Cadastro de Imóveis (Inclusão)</h3>
        <h3 v-else>Cadastro de Imóveis (Alteração)</h3>
      </v-card-title>
      <form id="formulario">
        <v-row class="linha">
          <v-col cols="2">
            <v-text-field 
              id="id" 
              name="id"
              dense
              disabled
              v-model="imovel.id"
              label="ID"
            >
            </v-text-field>
          </v-col>
          <v-col cols="5"></v-col>
          <v-col cols="5" class="imagemargin">
            <v-img
              id="imagem"
              :src="imagembase64" 
              alt="Sem imagem"
              width="200px"
              height="100px"
              @click="onUploadImageClick"
            />
          </v-col>
          <input
            ref="uploader"
            class="d-none"
            type="file"
            accept="image/*"
            @change="onFileChanged"
          />
        </v-row>

        <v-row class="linha">
          <v-col cols="6" v-if="usuario_logado == '0'">
            <v-select dense v-model="imovel.id_cliente" :items="clientes" :item-text="getClienteText" item-value="id"
              label="Cliente"></v-select>
          </v-col>
          <v-col cols="6" v-if="usuario_logado == '0'">
            <v-select dense v-model="imovel.id_usuario" :items="usuarios" :item-text="getUsuarioText" item-value="id"
              label="Usuário"></v-select>
          </v-col>
          <v-col cols="12">
            <v-text-field id="descricao" name="descricao" dense v-model="imovel.descricao" label="Descrição">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="linha">
          <v-col cols="6">
            <v-select dense v-model="imovel.id_cidade" :items="cidades" :item-text="getCidadeText" item-value="id"
              label="Cidade"></v-select>
          </v-col>
          <v-col cols="6">
            <v-select dense v-model="imovel.id_bairro" :items="bairros" :item-text="getBairroText" item-value="id"
              label="Bairro"></v-select>
          </v-col>
        </v-row>
        <v-row class="linha">
          <v-col cols="6">
            <v-select dense v-model="imovel.tipo_negocio" :items="tipos_negocios" :item-text="getTipoNegocioText"
              item-value="valor" label="Tipo de Negócio"></v-select>
          </v-col>
          <v-col cols="6">
            <vuetify-money dense v-model="imovel.valor" label="Valor" valueWhenIsEmpty="0.00"
              :options="{ locale: 'pt-BR', prefix: 'R$', suffix: '', length: 11, precision: 2 }" />
          </v-col>
        </v-row>
        <v-row class="linha">
          <v-col cols="6">
            <v-select dense v-model="imovel.tipo_imovel" :items="tipos_imoveis" :item-text="getTipoImovelText"
              item-value="valor" label="Tipo do Imóvel"></v-select>
          </v-col>
          <v-col cols="6">
            <v-select dense v-model="imovel.subtipo_imovel" :items="subtipos_imoveis" :item-text="getSubTipoImovelText"
              item-value="valor" label="Subtipo do Imóvel"></v-select>
          </v-col>
        </v-row>
        <v-row class="linha">
          <v-col cols="6">
            <vuetify-money dense v-model="imovel.areautil" label="Área Útil" valueWhenIsEmpty="0"
              :options="{ locale: 'pt-BR', prefix: '', suffix: 'm²', length: 11, precision: 2 }" />
          </v-col>
          <v-col cols="6">
            <vuetify-money dense v-model="imovel.areatotal" label="Área Total" valueWhenIsEmpty="0"
              :options="{ locale: 'pt-BR', prefix: '', suffix: 'm²', length: 11, precision: 2 }" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-slider v-model="imovel.suites" thumb-color="red" thumb-label="always" min="0" max="9"></v-slider>
          </v-col>
          <v-col cols="3">
            <v-slider v-model="imovel.quartos" thumb-color="red" thumb-label="always" min="0" max="9"></v-slider>
          </v-col>
          <v-col cols="3">
            <v-slider v-model="imovel.garagens" thumb-color="red" thumb-label="always" min="0" max="9"></v-slider>
          </v-col>
          <v-col cols="3">
            <v-slider v-model="imovel.ambientes" thumb-color="red" thumb-label="always" min="0" max="9"></v-slider>
          </v-col>
        </v-row>
        <v-row class="sliderstext" justify="center">
          <v-col cols="3">
            <h4>Suítes</h4>
          </v-col>
          <v-col cols="3">
            <h4>Quartos</h4>
          </v-col>
          <v-col cols="3">
            <h4>Ambientes</h4>
          </v-col>
          <v-col cols="3">
            <h4>Vagas de Garagem</h4>
          </v-col>
        </v-row>
        <v-row class="linha" justify="center">
          <v-text-field id="pasta_arquivos" name="pasta_arquivos" dense :disabled=true v-model="link"
            label="Link Tour Virtual" class="mr-3">
          </v-text-field>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn :disabled="(link == null) || (link.trim() == '')" v-bind="attrs" v-on="on" class="mr-1" color="green darken-1 white--text mb-10" @click="copiaLink">
                <svg-icon height="20px" width="20px" type="mdi" :path="copy"></svg-icon>
              </v-btn>
            </template>
            <span>Copiar Link</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn :disabled="(link == null) || (link.trim() == '')" v-bind="attrs" v-on="on" color="green darken-1 white--text mb-10" @click="visualizaTour">
                <svg-icon height="20px" width="20px" type="mdi" :path="tour"></svg-icon>
              </v-btn>
            </template>
            <span>Visualizar Tour</span>
          </v-tooltip>
        </v-row>
        <v-row v-if="usuario_logado == '0'" justify="center">
          <v-file-input v-model="arquivo" dense show-size label="Selecione o arquivo do tour virtual"></v-file-input>
        </v-row>
      </form>
      <v-btn class="botoes" color="blue darken-1" text @click="cancel"> Cancelar </v-btn>
      <v-btn class="botoes" color="blue darken-1" text @click="save"> Salvar </v-btn>
    </v-card>
    <v-snackbar v-model="snackbar" :timeout="timeout" color="red" shaped elevation="24" centered>
      <h2>{{ text }}</h2>
    </v-snackbar>
  </div>
</template>
  
<script>
import svcImoveis from "../../services/svcImoveis";
import svcClientes from "../../services/svcClientes";
import svcUsuarios from "../../services/svcUsuarios";
import svcCidades from "../../services/svcCidades";
import svcBairros from "../../services/svcBairros";
import SvgIcon from '@jamescoyle/vue-icon';
import { decriptar, encriptar } from "../../config/cripto";
import { SetarSubtiposImoveis, geraStringAleatoria, enviaEmail } from "../../config/funcoes"
import { mdiHomeCity, mdiRotate360, mdiContentCopy, mdiCloudUpload } from '@mdi/js';
import "../../config/consts";
import svcEmail from "../../services/svcEmail";

export default {
  components: {
    SvgIcon
  },
  data() {
    return {
      icone: mdiHomeCity,
      copy: mdiContentCopy,
      tour: mdiRotate360,
      upload: mdiCloudUpload,
      imovel: {
        id: "",
        id_cliente: 0,
        id_usuario: 0,
        descricao: "",
        tipo_negocio: 1,
        id_cidade: 0,
        id_bairro: 0,
        tipo_imovel: 1,
        subtipo_imovel: 1,
        valor: 0,
        suites: 0,
        quartos: 0,
        ambientes: 0,
        garagens: 0,
        areautil: 0,
        areatotal: 0,
        imagem: "",
        pasta_arquivos: "",
      },
      clientes: [],
      cliente_user: "",
      usuarios: [],
      usuario_logado: "",
      arquivo: "",
      cpfcnpj: "",
      link: "",
      passwordVisible: false,
      cidades: [],
      bairros: [],
      cidade: "",
      uf: "",
      bairro: "",
      tipos_negocios: [],
      tipos_imoveis: [],
      subtipos_imoveis: [],
      isSelecting: false,
      snackbar: false,
      text: "",
      timeout: 2000,
      imagem: null,
      imagembase64: "",
      imgheight: 0,
      imgwidth: 0,
    };
  },

  methods: {
    copiaLink() {
      this.$clipboard(this.link);
      this.text = "Link copiado para a área de transferência!";
      this.snackbar = true;
    },

    visualizaTour() {
      const routeData = this.$router.resolve("/imoveis/capa/" + encriptar(this.imovel.id));
      window.open(routeData.href, '_blank');
    },

    passwordVisibleToggle() {
      this.passwordVisible = !this.passwordVisible;
    },

    getTipoNegocioText(item) {
      return item.texto;
    },

    getTipoImovelText(item) {
      this.subtipos_imoveis = SetarSubtiposImoveis(item.valor);
      return item.texto;
    },

    getSubTipoImovelText(item) {
      return item.texto;
    },

    getClienteText(item) {
      this.lista_usuarios(item.id);
      return item.nome;
    },

    lista_clientes() {
      svcClientes
        .listar()
        .then((resp) => {
          this.clientes = resp.data;
        })
        .catch((error) => {
          alert(error.response.data.mensagem);
          localStorage.clear();
          this.$router.push("/login");
        });
    },

    getUsuarioText(item) {
      return item.nome;
    },

    lista_usuarios(id_cliente) {
      svcUsuarios
        .listar(id_cliente)
        .then((resp) => {
          this.usuarios = resp.data;
        })
        .catch((error) => {
          alert(error.response.data.mensagem);
          localStorage.clear();
          this.$router.push("/login");
        });
    },

    getCidadeText(item) {
      this.lista_bairros(item.id);
      return item.nome + " - " + item.uf;
    },

    lista_cidades() {
      svcCidades
        .listar()
        .then((resp) => {
          this.cidades = resp.data;
        })
        .catch((error) => {
          alert(error.response.data.mensagem);
          localStorage.clear();
          this.$router.push("/login");
        });
    },

    getBairroText(item) {
      return item.nome;
    },

    lista_bairros(id_cidade) {
      svcBairros
        .listar(id_cidade)
        .then((resp) => {
          this.bairros = resp.data;
        })
        .catch((error) => {
          alert(error.response.data.mensagem);
          localStorage.clear();
          this.$router.push("/login");
        });
    },

    pesquisa(id) {
      svcImoveis
        .pesquisar(id)
        .then((resp) => {
          this.imovel = resp.data;
          if ((this.imovel.imagem == null) || (this.imovel.imagem.trim() == "")) {
            this.imagembase64 = require("@/assets/logopadrao.png");
          } else {
            this.imagembase64 = process.env.VUE_APP_URL_FILES + "/l/" + this.imovel.imagem;
          }
          const img = document.getElementById('imagem'); 
          this.imgwidth = img.clientWidth;
          this.imgheight = img.clientHeight;
          this.buscaCpfCnpjCliente();
        })
        .catch((error) => {
          alert(error);
        });
    },

    async buscaCpfCnpjCliente() {
      this.cpfcnpj = "";

      svcClientes
        .pesquisar(this.imovel.id_cliente)
        .then((resp) => {
          this.cpfcnpj = resp.data.cpfcnpj;
          if ((this.imovel.pasta_arquivos == null) || (this.imovel.pasta_arquivos.trim() == "")) {
            this.link = "";
          } else {
            this.link = process.env.VUE_APP_URL_FILES + "/t/" + this.cpfcnpj + "/" + this.imovel.pasta_arquivos + "/app-files";
          }
        })
        .catch((error) => {
          alert(error.data.mensagem);
        });
    },

    cancel() {
      this.reposiciona();
    },

    save() {
      if (this.$route.fullPath == "/imoveis/new") {
        this.buscaCpfCnpjCliente();
        svcImoveis
          .incluir(this.imovel)
          .then((resp) => {
            if (resp.status == 200) {
              this.imovel = resp.data;
              this.enviaEmailNovo();
              svcImoveis
                .uploadimage(this.imovel.id, this.imagem)
                .then((resp1) => {
                  if (resp1.status == 200) {
                    this.imovel.imagem = resp1.data.imagem;
                    svcImoveis.alterar(this.imovel);
                    if ((this.arquivo.trim != "") && (this.arquivo != null)) {
                      svcImoveis
                        .uploadzip(this.cpfcnpj, geraStringAleatoria(8) + this.imovel.id, this.arquivo)
                        .then((resp2) => {
                          if (resp2.status == 200) {
                            this.imovel.pasta_arquivos = resp2.data.pasta_arquivos;
                            svcImoveis
                              .alterar(this.imovel)
                              .then((resp3) => {
                                if (resp3.status == 200) {
                                  this.enviaEmailArquivo(this.imovel.pasta_arquivos);
                                }
                              });
                          }
                        });
                    }
                  }
                  this.reposiciona();
                })
              }
            })
          .then((resp) => {
            if (resp.status == 200) {
              alert("Erro incluindo dados do cadastro.");
            }
          })
          .then(() => {
            this.reposiciona();
          });
          // .catch((error) => {
          //   alert("CATCH " + error);
          // });
      } else {
        var pasta_arquivos = this.imovel.pasta_arquivos;
        if ((pasta_arquivos == null) || (pasta_arquivos.trim() == "")) {
          pasta_arquivos = geraStringAleatoria(8) + this.imovel.id;
        };
        svcImoveis
          .uploadimage(this.imovel.id, this.imagem)
          .then((resp) => {
            if (resp.status == 200) {
              this.imovel.imagem = resp.data.imagem;
            }
            svcImoveis
              .uploadzip(this.cpfcnpj, pasta_arquivos, this.arquivo)
              .then((resp) => {
                if (resp.status == 200) {
                  this.imovel.pasta_arquivos = resp.data.pasta_arquivos;
                  this.enviaEmailArquivo(this.imovel.pasta_arquivos);
                }
                svcImoveis
                  .alterar(this.imovel)
                  .then((resp) => {
                    if (resp.status != 200) {
                      alert("Erro alterando dados do cadastro.");
                    }
                  })
                  .then(() => {
                    this.reposiciona();
                  })
                  .catch((error) => {
                    alert(error);
                  });
              })
          })
      }
      this.link = process.env.VUE_APP_URL_FILES + "/t/" + this.cpfcnpj + "/" + this.imovel.pasta_arquivos;
    },

    enviaEmailNovo() {
      var to = "contato@visitaimoveis360.com.br";
      var subject = "[VisitaImoveis360] Novo Imóvel Cadastrado";
      var body =
        "<h3 align='center'>Um novo imóvel foi cadastrado no portal.</h3>" +
        "<table align='center'>" +
        "	<tr><td>ID do Cliente:</td><td>" + decriptar(localStorage.getItem('cdi')) + "<td></tr>" +
        "	<tr><td>Nome do Cliente:</td><td>" + decriptar(localStorage.getItem('cno')) + "<td></tr>" +
        "	<tr><td>ID do Imóvel:</td><td>" + this.imovel.id + "<td></tr>" +
        "	<tr><td>Descricao do Imóvel:</td><td>" + this.imovel.descricao + "<td></tr>" +
        "</table>";

      enviaEmail(to, subject, body);
    },

    enviaEmailArquivo(pasta_arquivos) {
      var usuario = this.usuarios.filter(d => d.id === this.imovel.id_usuario);
      var to = usuario[0].email;
      var subject = "[VisitaImoveis360] Visita Virtual Atualizada no Imóvel";
      const routeData = this.$router.resolve("/imoveis/capa/" + encriptar(this.imovel.id));
      var body = 
          "<h3 align='center'>A visita virtual do imóvel descrito abaixo já está disponível para visualização.</h3>" +
          "<table align='center'>" +
          "	<tr><td>ID do Imóvel:</td><td>" + this.imovel.id + "<td></tr>" +
          "	<tr><td>Descricao do Imóvel:</td><td>" + this.imovel.descricao + "<td></tr>" +
          "	<tr><td>Link para a Visita Virtual:</td><td>" + process.env.VUE_APP_URL_FILES + "/t/" + this.cpfcnpj + "/" + pasta_arquivos + "/app-files" + "<td></tr>" +
          "	<tr><td>Link para a Página do Imóvel:</td><td>" + window.location.protocol + "//" + window.location.host + "/" + routeData.href + "<td></tr>" +
          "</table>";

      enviaEmail(to, subject, body);
    },

    reposiciona() {
      this.$router.push("/imoveis");
    },

    onUploadImageClick() {
        this.isSelecting = true;
        window.addEventListener(
          "focus",
          () => {
            this.isSelecting = false;
          },
          { once: true }
        );

        this.$refs.uploader.click();
      },

      onFileChanged(e) {
        this.imagem = e.target.files[0];

        var reader = new FileReader();

        const callback = () => {
          this.imagembase64 = reader.result;
        };

        reader.onload = callback;
        reader.readAsDataURL(this.imagem);
      },

      onDeleteImageClick() {
        // this.logomarca = null;
        // this.logomarcabase64 = null;
        // this.cliente.logomarca = null;      
      },
  },

  mounted() {
    this.usuario_logado = decriptar(localStorage.getItem("udi"));
    this.cliente_user = decriptar(localStorage.getItem("cdi"));
    this.lista_clientes();
    this.lista_cidades();
    this.tipos_negocios = tipos_negocios;
    this.tipos_imoveis = tipos_imoveis;
    if (this.$route.fullPath != "/imoveis/new") {
      this.pesquisa(decriptar(this.$route.params.id))
    } else {
      this.imovel.id_cliente = this.cliente_user;
      this.imovel.id_usuario = this.usuario_logado;
      this.buscaCpfCnpjCliente();
      this.imagembase64 = require("@/assets/logopadrao.png");
      const img = document.getElementById('imagem'); 
      this.imgwidth = img.clientWidth;
      this.imgheight = img.clientHeight;
    }
  },
};
</script>
  
<style scoped>
.botoes {
  margin-top: 5px;
}

.imagemargin {
    margin-top: -40px;
}

.linha {
  margin-top: -10px;
}

.sliderstext {
  margin-top: -55px;
  margin-bottom: 15px;
}
</style>
  